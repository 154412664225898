[data-theme="light"] {
  tr.Resumen.Total td,
  tr.Resumen.Total td.ant-table-cell-row-hover {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  tr.ant-descriptions-row td.description-content {
    color: black;
    font-weight: normal;
    background: white;
    max-width: 30;
  }
}

[data-theme="dark"] {
  tr.Resumen.Total td,
  tr.Resumen.Total td.ant-table-cell-row-hover {
    background-color: #6f6c6c;
    font-weight: bold;
  }

  tr.ant-descriptions-row td.description-content {
    color: white;
    font-weight: normal;
    background: #6f6c6c;
    max-width: 30;
  }
}

[data-theme="yellow"] {
  tr.Resumen.Total td,
  tr.Resumen.Total td.ant-table-cell-row-hover {
    background-color: #e4d4ac;
    font-weight: bold;
  }

  tr.ant-descriptions-row td.description-content {
    color: black;
    font-weight: normal;
    background: #e4d4ac;
    max-width: 30;
  }
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.ReportTable .ant-table-column-sorters{
  display: inline !important;
}

