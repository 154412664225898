.ant-form-vertical .custom-input .ant-form-item-label {
  padding-bottom: 0;
}

.custom-input .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.custom-input .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  border-color: #ff4d4f;
}

.custom-input {
  .suffix-container {
    position: absolute;
    top: 0.1em;
    right: 0.1em;
    padding: 0 5px;
    z-index: 2;
  }

  .suffix-number-container {
    position: absolute;
    top: 0.1em;
    right: 0.9em;
    padding: 0 5px;
    z-index: 2;
  }

  .ant-space.ant-space-horizontal {
    gap: 4px !important;
  }
}

.ant-input-group .custom-input .suffix-container {
  right: 5px;
}

.ant-row.ant-form-item.no-error-text,
.ant-form-item.no-error-text {
  margin: 0;

  .ant-form-item-explain {
    min-height: 0;
    height: 0;
  }
}

[data-theme="light"] {
  .custom-input .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .custom-input .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    background: white;
  }

  .custom-input {
    .suffix-container {
      background-color: white;
    }
  }

  .suffix-container.disabled {
    background-color: #f5f5f5;
  }
}

[data-theme="dark"] {
  .custom-input .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .custom-input .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    background: #303030;
  }

  .custom-input {
    .suffix-container {
      background-color: #303030;
    }
  }

  .suffix-container.disabled {
    background-color: #404040;
  }
}

[data-theme="yellow"] {
  .custom-input .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .custom-input .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    background: #ebdbb2;
  }

  .custom-input {
    .suffix-container {
      background-color: #ebdbb2;
    }
  }

  .suffix-container.disabled {
    background-color: #b9a162;
  }
}

