.ant-card.ant-card-bordered.ant-card-small.record-wallet {
  background-color: #253b65;
  opacity: 0.8;
  background-image: repeating-radial-gradient(
      circle at 0 0,
      transparent 0,
      #253b65 40px
    ),
    repeating-linear-gradient(#4b5e8b, #7283b3);
  border-radius: 1em;

  .ant-typography.wallet-title,
  .ant-typography.wallet-text {
    color: #ffffff;
    font-weight: 500;
  }
}

