[data-theme="light"] {
  .menu-contrast-custom-theme span,
  .menu-contrast-custom-theme svg {
    color: white;
    font-family: Arial!important;
    a {
      color: white;
      font-family: Arial!important;
    }

    + i {
      color: white;
      font-family: Arial!important;
    }
  }

  h5.title-shortcut {
    color: #ffffff;
    text-align: center;
  }

  .shortcuts.ant-tooltip-inner {
    background-color: #e7f6fd;
  }
}

[data-theme="yellow"] {
  .menu-contrast-custom-theme span,
  .menu-contrast-custom-theme svg {
    color: black;
    font-family: Arial!important;

    a {
      color: black;
      font-family: Arial!important;

    }

    + i {
      color: black;
      font-family: Arial!important;

    }
  }
  .table-row:hover {
    background-color: #f0f8ff; /* Cambia al color deseado */
    cursor: pointer;
  }
  .ant-tooltip-inner.tooltip-shortcuts {
    background-color: #d0af70;
  }

  h5.title-shortcut {
    color: #f5f5f5;
    text-align: center;
  }
}

[data-theme="dark"] {
  .ant-tooltip-inner.tooltip-shortcuts {
    background-color: #1d1d1d;
  }

  h5.title-shortcut {
    color: #f5f5f5;
    text-align: center;
  }

  .ant-ribbon.badge-shortcut {
    background-color: #2857ad;
  }
}

.ant-card.ant-card-bordered.ant-card-small.shortcut-card {
  width: 15.5em;
}

.ant-comment.notification-comment .ant-comment-inner {
  padding-top: 0;
}

