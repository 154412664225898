[data-theme="light"] {
  div.ant-collapse-content.ant-collapse-content-active
    div.ant-collapse-content-box
    div.status-container {
    background-color: #f2f2f2;
    border-style: solid;
    border-color: #cbc9c9;
    border-width: 1px;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
  }
}

[data-theme="dark"] {
  div.ant-collapse-content.ant-collapse-content-active
    div.ant-collapse-content-box
    div.status-container {
    background-color: #424242;
    border-style: solid;
    border-color: #a39f9f;
    border-width: 1px;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
  }
}

[data-theme="yellow"] {
  div.ant-collapse-content.ant-collapse-content-active
    div.ant-collapse-content-box
    div.status-container {
    background-color: #e4d4ac;
    border-style: solid;
    border-color: #ac9e78;
    border-width: 1px;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
  }
}

