[data-theme="light"] {
  div.status-container {
    background-color: #f2f2f2;
    border-style: solid;
    border-color: #cbc9c9;
    border-width: 1px;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;
  }

  div.recordInfo-container {
    background-color: #fafafa;
    border: 1px solid #cbc9c9;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 16px;
    width: auto;
    // position: sticky;
    // top: 5px;
    z-index: 1;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  .ant-typography h5,
  div.ant-typography-h5,
  div.ant-typography-h5 > textarea,
  h5.ant-typography.clinicTitle {
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 16px;
    line-height: 0;
  }

  div.capture-observartions {
    background-color: #ffffbf;
  }

  div.capture-xray {
    background-color: #e5f0ff;
  }

  div.capture-details {
    background-color: #f4f3ef;
  }
}

[data-theme="dark"] {
  div.status-container {
    background-color: #424242;
    border-style: solid;
    border-color: #a39f9f;
    border-width: 1px;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;
  }

  div.recordInfo-container {
    background-color: #424242;
    border: 1px solid #a39f9f;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    width: auto;
    // position: sticky;
    // top: 5px;
    z-index: 1;
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
  }

  .ant-typography h5,
  div.ant-typography-h5,
  div.ant-typography-h5 > textarea,
  h5.ant-typography.clinicTitle {
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 600;
    font-size: 16px;
    line-height: 0;
  }

  div.capture-observartions {
    background-color: #424242;
  }

  div.capture-details {
    background-color: #6f6c6c;
  }
}

[data-theme="yellow"] {
  div.status-container {
    background-color: #eef1f9;
    border-style: solid;
    border-color: #ac9e78;
    border-width: 1px;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;
  }

  div.recordInfo-container {
    background-color: #eef1f9;
    border: 1px solid #ac9e78;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    // position: sticky;
    // top: 5px;
    z-index: 1;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  .ant-typography h5,
  div.ant-typography-h5,
  div.ant-typography-h5 > textarea,
  h5.ant-typography.clinicTitle {
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 16px;
    line-height: 0;
  }

  div.capture-observartions {
    background-color: #ffffbf;
  }

  div.capture-details {
    border: 1px solid #a3a190;
  background-color: #f6f7ed; 
  }
}

div.ant-row.study-divider {
  border-radius: 0.25em;
  border-style: solid;
  border-width: 1px;
  border-color: #1c91ff;
  background-color: #e6f7ff;
  padding: 0.4em;
  margin: 1.5em;
  position: sticky;
  top: 0;
  z-index: 5;
  padding: 0;
  margin-bottom: 4px;
}

.study-result-container:first-of-type div.ant-row.study-divider {
  margin-top: 0px;
}

span.ant-typography.result-study {
  color: #1c91ff;
  font-weight: 500;
  margin-left: 0.625em;
  padding-right: 10px;
}

.ant-btn.ant-btn-primary.ant-btn-sm.update-result {
  background-color: #1c91ff;
  color: #f2f2f2;
  border-color: #1c91ff;
}

.ant-btn.ant-btn-primary.ant-btn-sm.manual-submission {
  background-color: #6eaa46;
  color: #f2f2f2;
  border-color: #6eaa46;
}

.ant-collapse.request-filter-collapse
  > .ant-collapse-item
  > .ant-collapse-header,
.ant-collapse.request-filter-collapse
  > .ant-collapse-item
  .ant-collapse-content-box {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.ant-collapse.request-filter-collapse button ~ button {
  margin-left: 8px;
}

.ant-input-affix-wrapper > .ant-input.result-textarea {
  overflow: hidden;
  border: 1px solid #d9d9d9;
}

.editor-class {
  background-color: white;
  padding: 0px 12px;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}

.upload-list-inline [class*="-upload-list-rtl"] .ant-upload-list-item {
  float: right;
}

.input-placeholder::placeholder {
  color: red;
}

.filter-buttons {
  text-align: right;
  margin-bottom: 10px;
}

.center-input input {
  text-align: center;
}

.site-description-item-profile-wrapper {
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
}

.ant-drawer-body p.site-description-item-profile-p {
  display: block;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5715;
}

.site-description-item-profile-p-label {
  display: inline-block;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.85);
}

.no-padding-table td {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

