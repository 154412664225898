[data-theme="light"] {
  .ant-descriptions-row:nth-child(even) {
    th,
    td {
      background-color: #d0d8e8;
    }
  }

  .ant-descriptions-row:nth-child(odd) {
    th,
    td {
      background-color: #e9edf4;
    }
  }

  .ant-descriptions-row:first-child {
    th,
    td {
      background-color: #4f81bd;
      color: white;
      font-weight: bold;
    }
  }
}

[data-theme="dark"] {
  .ant-descriptions-row:nth-child(even) {
    th,
    td {
      background-color: #303030;
    }
  }

  .ant-descriptions-row:nth-child(odd) {
    th,
    td {
      background-color: #404040;
    }
  }

  .ant-descriptions-row:first-child {
    th,
    td {
      background-color: #525252;
      color: white;
      font-weight: bold;
    }
  }
}

[data-theme="yellow"] {
  .ant-descriptions-row:nth-child(even) {
    th,
    td {
      background-color: #e9d297;
    }
  }

  .ant-descriptions-row:nth-child(odd) {
    th,
    td {
      background-color: #ebdbb2;
    }
  }

  .ant-descriptions-row:first-child {
    th,
    td {
      background-color: #b9a162;
      color: white;
      font-weight: bold;
    }
  }
}

.ant-descriptions.quotation-description {
  .ant-descriptions-row {
    th,
    td {
      padding: 4px 4px;
    }

    td.number-desc {
      padding-left: 11px;
    }
  }

  .ant-descriptions-row:last-child {
    th,
    td {
      color: #ff4d4f;
      font-weight: bold;
    }
  }
}

.quotation-radio {
  .ant-radio-wrapper {
    margin-right: 0;
  }
}

.badge-container {
  width: 15px;
  height: 15px;
  margin: auto;
  border-radius: 50%;
}

.badge-container-large {
  width: 22px;
  height: 22px;
  margin: auto;
  text-align: center;
  border-radius: 50%;
}

.quotation-indication-list {
  .ant-comment-inner:first-child {
    padding-top: 0;
  }

  .ant-comment-content-author span {
    color: rgba(0, 0, 0, 0.75);
  }
}

.requet-image-segment .ant-segmented-item-selected {
  box-shadow: 0px 0px 3px #1890ff;
}

.ant-divider-horizontal.ant-divider-with-text.register-cancelled-divider {
  margin: 8px 0;
  color: #ff4d4f;
}

.ant-steps-small.wee-token-validation-step {
  margin-bottom: 18px;
}

.ant-steps-small.wee-token-validation-step
  .ant-steps-item-custom
  .ant-steps-item-icon
  > .ant-steps-icon {
  font-size: 18px;
}

